import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import icon from "../images/icon.ico";
// import favicon from "../images/gorilla.png";

import "../scss/gatstrap.scss";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content: "script writing agency",
            },
            {
              name: "keywords",
              content: "bingbong",
            },
          ]}
          link={[
            {
              rel: "icon",
              type: "image/x-icon",
              sizes: "16x16",
              href: `${icon}`,
            },
          ]}
        />

        <main>{children}</main>
        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
