import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, lang, dir, meta, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
              dir,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: "google-site-verification",
                content: "xZQ0gMBJYr3HCtcncistlvXn06o_0TAJ-qD0yFfRoYU",
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `keywords`,
                content: [
                  "bingbong studios",
                  "bingbong",
                  "بينج بونج",
                  "amr sokkar",
                  "ahmed saad",
                  "haisam yehia",
                  "عمرو سكر",
                  "عندما يقع الإنسان في مستنقع افكاره فينتهي به الأمر إلى المهزلة",
                  "netflix",
                  "shahid",
                  "SNL",
                  "fahita",
                  "مسلسل",
                  "فيلم",
                  "writing agency",
                  "شركة كتابة",
                  "Logline",
                  "Concept & World",
                  "Synopsis",
                  "Show Cross",
                  "Character Profiles",
                  "Character Arcs",
                  "Seasons Map",
                  "Pilot Design",
                  "Season Beats",
                  "Formats",
                  "Episodes Scripts",
                  "Script Doctoring",
                ],
              },
            ].concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: ``,
  dir: "",
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  dir: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
