import { Link } from "gatsby";
import React from "react";
import logo from "../images/logo.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import styled from "styled-components";

const HeaderWrapper = styled.header`
  background: #231f20;
`;

const Nav = styled.nav`
  padding: 1rem;
  display: flex;
  li {
    cursor: pointer;
  }

  /* position: fixed; */
  width: 100%;
`;
const Logo = styled(Link)`
  padding: 1rem 2rem;
  opacity: 0;
  animation: fade-in-right ease 0.4s forwards;
  img {
    width: 70%;
  }
`;
const Trigger = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: auto;
  left: auto;
  z-index: 3;
  cursor: pointer;
  transition: top 0.1s ease-in-out;
  display: none;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background: #f4ecd2;
    margin: 0 0 5px 0;
    transition: all 0.2s ease-in-out;
    position: absolute;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 10px;
      left: 0;
    }

    &:last-child {
      top: 20px;
      left: 0;
    }
  }

  &.on {
    top: 40px;
    span {
      background: #e75b95;
    }
    span:first-child {
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      transform: rotate(-45deg);
      top: 0;
    }

    span:last-child {
      transform: rotate(45deg);
      top: 0;
    }
  }
  @media (max-width: 1050px) {
    display: unset;
  }
`;
const Menu = styled.div`
  background: #231f20;
  color: #e75b95;
  position: fixed;
  margin: auto;
  height: 96%;
  width: 95%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ toggle }) => (toggle ? "block" : "none")};
  z-index: 2;
  a {
    color: #e75b95;
    :hover {
      color: #f4ecd2;
    }
    .active {
      color: #e75b95;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    left: 0;
    width: 250px;
    text-align: center;
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    li {
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      color: #e75b95;
      font-size: 24px;
      padding: 10px 0;
      cursor: pointer;
    }
    .active {
      color: #e75b95;
    }
  }
`;
const DesktopNav = styled.ul`
  display: flex;
  transform: scaleX(0);
  transform-origin: left;
  animation: grow-left cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s forwards;
  animation-delay: 0.4s;
  a {
    color: #e75b95;
    :hover {
      color: #f4ecd2;
    }
    font-weight: 900;
    cursor: pointer;
  }
  text-transform: uppercase;
  place-self: center;
  @media (max-width: 1050px) {
    display: none;
  }
`;
const Header = () => {
  const [toggle, setToggle] = React.useState(false);

  return (
    <HeaderWrapper>
      <Nav>
        <Logo to="/">
          <img src={logo} alt="bingbong studios logo" />
        </Logo>
        <Trigger
          className={` ${toggle && "on"} `}
          showMobile={true}
          onClick={() => setToggle(!toggle)}
        >
          <span />
          <span />
          <span />
        </Trigger>
        <Menu toggle={toggle}>
          <ul>
            <li>
              <Link
                to="/#who"
                activeClassName="active"
                onClick={() => {
                  scrollTo("#who");
                  setToggle(false);
                }}
              >
                who
              </Link>
            </li>
            <li>
              <Link
                to="/#work"
                onClick={() => {
                  scrollTo("#work");
                  setToggle(false);
                }}
              >
                work
              </Link>
            </li>

            <li>
              <Link
                to="/my-bingbong"
                onClick={() => {
                  setToggle(false);
                }}
              >
                My BingBong
              </Link>
            </li>
            <li>
              <Link
                to="/#contact"
                onClick={() => {
                  setToggle(false);
                }}
              >
                contact us
              </Link>
            </li>
          </ul>
        </Menu>
        <DesktopNav>
          <li>
            <Link
              className="nav-link"
              to="/#who"
              onClick={() => scrollTo("#who")}
            >
              who
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="/#work"
              onClick={() => scrollTo("#work")}
            >
              work
            </Link>
          </li>

          <li>
            <Link className="nav-link" to="/my-bingbong">
              My BingBong
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="/#contact"
              onClick={() => scrollTo("#contact")}
            >
              contact us
            </Link>
          </li>
        </DesktopNav>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
