import React from "react";
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  bottom: 0;
  color: #f4ecd2;
  background: #231f20;
  padding: 5px;

  display: flex;
  flex-direction: column;
  place-items: center;

  a {
    color: #f4ecd2;
    :hover {
      color: #e75b95;
    }
    font-size: 2rem;
    margin: 0 0.4rem;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <div className="float-right">
      <span className="icon">
        <a
          href="https://www.facebook.com/bingbongstudios"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare />
        </a>
      </span>
      <span className="icon">
        <a
          href="https://www.youtube.com/channel/UCga9JMZxaQuNiJL4fy6Jh_A"
          target="_blank"
          rel="noreferrer"
        >
          <FaYoutube />
        </a>
      </span>
      <span className="icon">
        <a
          href="https://www.instagram.com/bingbongstudios/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </span>
      <span className="icon">
        <a
          href="https://twitter.com/BingBongStudio"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
      </span>
    </div>
    <br />
    <div className="">Copyright BingBong Studios. All Rights Reserved. </div>
  </FooterWrapper>
);
export default Footer;
